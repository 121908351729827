import { inject }                                   from 'aurelia-framework';
import { CreateMaintenanceTechnicianModal }         from 'modules/entities/maintenance-technicians/create-modal/index';
import { DialogService }                            from 'aurelia-dialog';
import { MaintenanceTechniciansRepository }         from 'modules/entities/maintenance-technicians/services/repository';
import { ProcedureExecution }                       from 'modules/procedures/models/procedure-execution';
import { SealingsAwaitingExecutionFilesRepository } from 'modules/sealings/awaiting-execution/services/files-repository';

@inject(DialogService, MaintenanceTechniciansRepository, SealingsAwaitingExecutionFilesRepository)
export class FormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {};

    /**
     * Constructor
     *
     * @param dialogService
     * @param maintenanceTechniciansRepository
     * @param filesRepository
     */
    constructor(dialogService, maintenanceTechniciansRepository, filesRepository) {
        this.dialogService                    = dialogService;
        this.maintenanceTechniciansRepository = maintenanceTechniciansRepository;
        this.filesRepository                  = filesRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {ProcedureExecution}
     */
    model() {
        let model = new ProcedureExecution();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns the form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    schema(viewModel) {
        this.inspector = {
            type:       'text',
            key:        'inspector',
            label:      'form.field.inspector',
            size:       4,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.scheduled_date = {
            type:            'text',
            key:             'scheduled_date',
            label:           'form.field.scheduled-date',
            size:            4,
            required:        false,
            attributes:      {
                readonly: true,
            },
            valueConverters: [
                { name: 'dateTimeFormat' },
            ],
        };

        this.maintenance_technician_id = {
            type:         'select2',
            key:          'maintenance_technician_id',
            label:        'form.field.maintenance-technician',
            size:         4,
            hidden:       viewModel.model.maintenance_company_id === null,
            options:      [],
            remoteSource: this.maintenanceTechniciansRepository.activeByEntity.bind(this.maintenanceTechniciansRepository, viewModel.model.maintenance_company_id),
            inputGroup:   {
                position: 'right',
                button: {
                    title:   'form.button.create-new',
                    action:  () => this.createNewMaintenanceTechnician(viewModel),
                    icon:    { class: 'icon-plus3' },
                    visible: viewModel.appContainer.authenticatedUser.can(['entities.maintenance-technicians.manage', 'entities.maintenance-technicians.create']),
                },
            },
        };

        this.execution_date = {
            type:            'material-ui-date-picker',
            key:             'execution_date',
            label:           'form.field.execution-date',
            size:            4,
            observers:       [
                (newValue) => viewModel.model.calculateCertificateDeadlines(viewModel.settings),
            ],
            valueConverters: [
                { name: 'dateFormat' },
            ],
        };

        this.started_at = {
            type:     'material-ui-time-picker',
            key:      'started_at',
            label:    'form.field.entry-time',
            size:     4,
            required: false,
        };

        this.ended_at = {
            type:     'material-ui-time-picker',
            key:      'ended_at',
            label:    'form.field.departure-time',
            size:     4,
            required: false,
        };

        this.next_execution_deadline = {
            type:            'text',
            key:             'next_execution_deadline',
            label:           'form.field.certificate-deadline',
            size:            4,
            hidden:          true,
            required:        false,
            attributes:      {
                readonly: true,
            },
            valueConverters: [
                { name: 'dateFormat' },
            ],
        };

        this.next_request_deadline = {
            type:            'text',
            key:             'next_request_deadline',
            label:           'form.field.require-next-inspection-until',
            size:            4,
            hidden:          true,
            required:        false,
            attributes:      {
                readonly: true,
            },
            valueConverters: [
                { name: 'dateFormat' },
            ],
        };

        this.observations = {
            type:     'textarea',
            key:      'observations',
            label:    'form.field.observations',
            size:     12,
            required: false,
        };

        this.report_id = {
            type:  'file-dropzone',
            key:   'report_id',
            label: 'form.field.report',
            size:  12,
            url:   this.filesRepository.uploadUri(),
        };

        this.backButton = {
            type:       'button',
            label:      'form.button.back',
            action:     () => viewModel.redirectToRoute('sealings.awaiting-execution.index'),
            attributes: {
                class: 'btn btn-light',
            },
            icon: {
                attributes: {
                    class: 'icon-chevron-left',
                },
            },
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.submit',
            action:     () => viewModel.submit(),
            attributes: {
                class: 'btn btn-primary',
            },
            icon: {
                right:      true,
                attributes: {
                    class: 'icon-chevron-right',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.backButton,
                this.submitButton,
            ],
        };

        return [
            [this.inspector, this.maintenance_technician_id, this.scheduled_date],
            [this.execution_date, this.started_at, this.ended_at],
            [this.next_execution_deadline, this.next_request_deadline],
            [this.observations],
            [this.report_id],
            [this.buttons],
        ];
    }

    /**
     * Opens the maintenance technicians creation modal
     *
     * @param viewModel
     */
    createNewMaintenanceTechnician(viewModel) {
        this.dialogService
            .open({
                viewModel: CreateMaintenanceTechnicianModal,
                model:     {
                    entity_id: viewModel.model.maintenance_company_id,
                },
            })
            .whenClosed((response) => {
                if (! response.wasCancelled) {
                    this.maintenance_technician_id.instance.fetchData().then(() => viewModel.model.maintenance_technician_id = response.output);
                }
            });
    }

}
