import { BaseRepository } from 'modules/core/services/base-repository';

export class SealingOrdersSchedulingRepository extends BaseRepository {

    baseUrl = 'sealing-orders/scheduling';

    /**
     * Exports a search given some criteria
     *
     * @param criteria
     *
     * @returns {*}
     */
    export(criteria) {
        return this.httpClient.rawPost(`${this.baseUrl}/export`, criteria)
            .then(response => {
                if (response.status !== 200) {
                    throw { status: false, message: 'Error' };
                } else {
                    return response.blob();
                }
            });
    }

    /**
     * Schedules a record
     *
     * @param id
     * @param data
     *
     * @returns {*}
     */
    schedule(id, data) {
        return this.httpClient.patch(`${this.baseUrl}/${id}/schedule`, data);
    }

    /**
     * Confirms a record's scheduling
     *
     * @param id
     * @param data
     *
     * @returns {*}
     */
    confirm(id, data) {
        return this.httpClient.patch(`${this.baseUrl}/${id}/confirm`, data);
    }

    /**
     * Rejects a record's scheduling
     *
     * @param id
     * @param data
     *
     * @returns {*}
     */
    reject(id, data) {
        return this.httpClient.patch(`${this.baseUrl}/${id}/reject`, data);
    }

}
