import { bindable, inject }                    from 'aurelia-framework';
import { AppContainer }                        from 'resources/services/app-container';
import { BaseFormViewModel }                   from 'base-form-view-model';
import { DialogService }                       from 'aurelia-dialog';
import { FormSchema }                          from 'modules/sealings/awaiting-execution/reschedule/form-schema';
import { RescheduleProcedureDialog }           from 'resources/elements/html-elements/dialogs/reschedule-procedure-dialog';
import { SealingsAwaitingExecutionRepository } from 'modules/sealings/awaiting-execution/services/repository';

@inject(AppContainer, DialogService, FormSchema, SealingsAwaitingExecutionRepository)
export class RescheduleSealing extends BaseFormViewModel {

    @bindable headerTitle = 'form.title.reschedule-sealing';
    @bindable formId      = 'sealings-awaiting-execution-rescheduling-form';

    alert  = {};
    model  = {};
    schema = {};

    /**
     * Constructor
     *
     * @param appContainer
     * @param dialogService
     * @param formSchema
     * @param repository
     */
    constructor(appContainer, dialogService, formSchema, repository) {
        super(appContainer);

        this.dialogService = dialogService;
        this.formSchema    = formSchema;
        this.repository    = repository;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return PLATFORM.moduleName('modules/sealings/awaiting-execution/form.html');
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'sealings.awaiting-execution.manage',
            'sealings.awaiting-execution.edit',
        ]);
    }

    /**
     * Handles activate event
     */
    activate(params, navigationInstruction) {
        super.activate(navigationInstruction);

        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        return this.fetchData(params).then(() => this.schema = this.formSchema.schema(this));
    }

    /**
     * Handles attached event
     */
    attached() {
        super.attached();

        this.headerSubtitle = this.model.code;
    }

    /**
     * Fetches data from server
     *
     * @param {id}
     *
     * @returns {Promise.<*>|Promise}
     */
    fetchData({ id }) {
        return this.repository.find(id).then((response) => {
            this.initialModel.assign(response);
            this.model.assign(response);
        });
    }

    /**
     * Submits view form
     */
    submit() {
        this.dialogService
            .open({
                viewModel: RescheduleProcedureDialog,
                model:     { type: 'resource.sealings.sealing' },
            })
            .whenClosed(response => {
                if (!response.wasCancelled) {
                    return this.repository
                        .reschedule(this.model.id, this.model)
                        .then(httpResponse => this.afterSubmitCallback(httpResponse))
                        .then(httpResponse => httpResponse.status === true ? this.appContainer.router.navigateToRoute('sealings.awaiting-execution.index') : false);
                }
            });

        return false;
    }

    /**
     * Handles the submit form response
     *
     * @param response
     */
    afterSubmitCallback(response) {
        if (response.status === true) {
            this.appContainer.notifier.successNotice(response.message);

            this.initialModel.assign(this.model);
        } else {
            this.alert = this.alertMessage(response.status, response.message, response.errors);
        }

        return response;
    }

}
