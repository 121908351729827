import { inject }                     from 'aurelia-framework';
import { BooleanOption }              from 'modules/administration/models/boolean-option';
import { LiftsRepository }            from 'modules/lifts/lifts/services/repository';
import { ProcedureMotivesRepository } from 'modules/procedures/motives/services/repository';
import { ProcedureRequest }           from 'modules/procedures/models/procedure-request';

@inject(LiftsRepository, ProcedureMotivesRepository)
export class FormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {
        notify_id: BooleanOption.YES,
    };

    /**
     * Constructor
     *
     * @param liftsRepository
     * @param procedureMotivesRepository
     */
    constructor(liftsRepository, procedureMotivesRepository) {
        this.liftsRepository            = liftsRepository;
        this.procedureMotivesRepository = procedureMotivesRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {ProcedureRequest}
     */
    model() {
        let model = new ProcedureRequest();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @returns {[*]}
     */
    schema(viewModel) {
        this.lifts = {
            type:           'multiselect-native',
            key:            'lifts',
            label:          'form.field.select-the-lift(s)-to-add',
            size:           12,
            options:        [],
            remoteSource:   this.liftsRepository.all.bind(this.liftsRepository),
            processResults: (row) => {
                row.name = `${row.code} - ${row.address}`;

                return row;
            },
        };

        this.motive_id = {
            type:         'select2',
            key:          'motive_id',
            label:        'form.field.motive',
            size:         6,
            options:      [],
            remoteSource: this.procedureMotivesRepository.all.bind(this.procedureMotivesRepository),
        };

        this.notify_id = {
            type:  'boolean-options-select',
            key:   'notify_id',
            label: 'form.field.send-prior-hearing-notification',
            size:  6,
        };

        this.clearButton = {
            type:       'button',
            label:      'form.button.clear',
            action:     () => {
                viewModel.model.motive_id = null;
                viewModel.model.lifts.splice(0, viewModel.model.lifts.length);
            },
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-close2',
                },
            },
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.register-request',
            action:     () => viewModel.submit(),
            attributes: {
                class: 'btn btn-primary',
            },
            icon:       {
                right:      true,
                attributes: {
                    class: 'icon-chevron-right',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.clearButton,
                this.submitButton,
            ],
        };

        return [
            [this.lifts],
            [this.motive_id, this.notify_id],
            [this.buttons],
        ];
    }

}
